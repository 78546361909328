import * as React from 'react';
import { Button } from '../button/button';
import styles from './webinar-button.module.scss';

export const WebinarButton = props => {
  return (
    <div className={styles.WebinarButton}>
      <Button {...props} />
    </div>
  );
};
