import { ContentItem } from '@kentico/kontent-delivery';
import get from 'lodash/get';

import { Models } from './index';
import { IEmployeeBoxProps } from '../components/employee-box/employee-box';

import { IArticleItem } from '../components/blog/article-list-item/article-list-item';
import { IButtonProps } from '../components/button/button';
import { ButtonModel } from './button-model';

export class WebinarArticleModel extends ContentItem {
  get id(): string {
    return get(this._config, 'system.id', '');
  }

  get language(): string {
    return get(this._config, 'system.language', '');
  }

  get slug(): string {
    return get(this._config, 'elements.basic_page_settings__url.value', '');
  }

  get title(): string {
    return get(this._config, 'elements.title_value.value', '');
  }

  get summary(): string {
    return get(this._config, 'elements.summary.value', '');
  }

  get author(): IEmployeeBoxProps[] {
    const authorsData = get(this._config, 'elements.author.value', []);
    const modeledData: IEmployeeBoxProps[] = [];

    authorsData.map(
      (authorData, i: number) =>
        (modeledData[i] = Models.EmployeeBoxModel.create(
          authorData as IEmployeeBox
        ))
    );

    return modeledData;
  }

  get image(): {
    url: string;
    description: string;
  } {
    return get(this._config, 'elements.image.value[0]', '');
  }

  get publicationDate(): string {
    return get(this._config, 'elements.publication_data.value', '');
  }

  get button(): IButtonProps | null {
    const getButton = get(
      this._config,
      'elements.watch_webinar_button.value[0]',
      null
    );

    const transformButton = getButton ? ButtonModel.create(getButton) : null;
    return transformButton as IButtonProps;
  }

  get content(): {
    value: IKontentResolvedData;
    modular_content: IKontentItem[];
  } {
    return get(this._config, 'elements.content', '');
  }

  public static create(data: IWebinarArticle): IArticleItem {
    const {
      language,
      slug,
      title,
      summary,
      image,
      id,
      content,
      publicationDate,
      author,
      button
    } = new WebinarArticleModel(data);

    return {
      language,
      id,
      slug,
      title,
      summary,
      image,
      author,
      content,
      button,
      publicationDate
    };
  }
}
