import { graphql } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  useContext,
  useEffect,
  FunctionComponent,
  useMemo
} from 'react';
import { PageProps } from 'gatsby';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import { pageDataResolver } from '../utils/pageDataResolver';
import { SeoComponent } from '../components/seo-component/seo-component';
import { Navbar } from '../components/navbar/navbar';
import { Footer } from '../components/footer/footer';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { WebinarArticleModel } from '../models/webinar-article.model';
import { ArticleHero } from '../components/article-hero/article-hero';
import { BlogAuthorSection } from '../components/blog/blog-author-section/blog-author-section';
import { seoDataResolver } from '../components/seo-component/seoDataResolver';
import { WEBINAR_URL } from '../const/url';
import { withCookieBar } from '../hoc/withCookieBar';
import RichText from '../components/rich-text/rich-text';
import { IEmployeeBoxProps } from '../components/employee-box/employee-box';
import '../styles/global.scss';
import '../styles/reset.css';
import { WebinarButton } from '../components/webinar-button/webinar-button';
export interface IWebinarArticleItem {
  language: string;
  id: string;
  title: string;
  summary: string;
  publicationDate: string;
  slug: string;
  image: {
    url: string;
    description: string;
  };
  button: IButton;
  author: IEmployeeBoxProps[];
  content: {
    value: IKontentResolvedData;
    modular_content: IKontentItem[];
  };
}

interface IData {
  kontentItemPage: IPage;
  cookieSection: ICookieData;
  kontentItemWebinar: IWebinarArticle;
}

type WebinarPageProps = PageProps<IData, IPageContext>;

const WebinarPage: FunctionComponent<WebinarPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.pageContext.pageUrl || props.uri,
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);

    handleResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    state.showActiveDevice,
    state.showPageState.currentUri,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    props.location.state,
    props.data.cookieSection.elements.text.value,
    props.data.cookieSection.elements.button_cta.value
  ]);

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  const webinarData: IWebinarArticleItem = useMemo(
    () => WebinarArticleModel.create(props.data.kontentItemWebinar),
    [props.data.kontentItemWebinar]
  );

  return (
    <>
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <ArticleHero
        linkBackUrl={WEBINAR_URL}
        backgroundImage={webinarData.image}
        headline={webinarData.title}
        subTitle={webinarData.summary}
        title="WEBINARS"
      />
      <WebinarButton {...webinarData.button}></WebinarButton>

      {/* <Container>
        <div className={styles.blogArticleWrapper}>
          <ShareButtons className={styles.shareButtons} />
          <div className={styles.kenticoContent}>
            {webinarData.contentHtml && (
              <BlogArticleContent
                className={styles.blogArticleContent}
                content={webinarData.contentHtml}
              />
            )}
          </div>
          <div></div>
        </div>
      </Container> */}

      {webinarData && <RichText {...webinarData.content} />}

      {webinarData.author.map(
        author => author.photoUrl && <BlogAuthorSection author={author} />
      )}

      {pageData.footerData && <Footer {...pageData.footerData} />}
    </>
  );
};

export const Head = (props: WebinarPageProps) => {
  const seoData = useMemo(
    () =>
      props.data.kontentItemWebinar.elements
        ? seoDataResolver(props.data.kontentItemWebinar.elements)
        : null,
    [props.data.kontentItemWebinar.elements]
  );

  return <SeoComponent {...seoData} />;
};

export default withAppStore(
  withCookieBar(WebinarPage as FunctionComponent<{}>)
);

export const query = graphql`
  query($id: String, $pageLanguage: String) {
    kontentItemPage(
      system: { language: { eq: $pageLanguage }, codename: { eq: "homepage" } }
    ) {
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
    kontentItemWebinar(
      system: { id: { eq: $id }, language: { eq: $pageLanguage } }
    ) {
      ...FragmentWebinar
    }
  }
`;
